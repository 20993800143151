import { Color, colors } from "@mui/material";
import {
  createMuiTheme,
  Theme as MuiTheme,
  adaptV4Theme,
  ThemeOptions
} from "@mui/material/styles";
import type {
  Palette as MuiPalette,
  TypeBackground as MuiTypeBackground
} from "@mui/material/styles/createPalette";
import type { Shadows as MuiShadows } from "@mui/material/styles/shadows";
import _ from "lodash";
import { THEMES } from "../constants";
import { DamageStatus } from "../types/damage";
import { CombinedTripEventType } from "../types/trip";
import {
  RiskScore,
  VehicleStatus as VehicleStatusType
} from "../types/vehicle";
import { VehicleCleanStatus } from "../types/vehicle";
import { GeofencingType } from "../types/zone";
import { softShadows, strongShadows } from "./util-shadows";
import typography from "./util-typography";
import { DunningState, Invoice } from "../types/invoice";
import { Schedule } from "../types/schedule";
import { Repair } from "../types/repair";
import { FineStatus } from "../types/fine";

declare module "@mui/styles/defaultTheme" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

interface TypeBackground extends MuiTypeBackground {
  dark: string;
}

interface Palette extends MuiPalette {
  background: TypeBackground;
}

export interface Theme extends MuiTheme {
  name: string;
  palette: Palette;
}

type Direction = "ltr" | "rtl";

interface ThemeConfig {
  theme?: string;
}

interface CustomThemeOptions {
  name?: string;
  direction?: Direction;
  typography?: Record<string, any>;
  overrides?: Record<string, any>;
  palette?: Record<string, any>;
  shadows?: MuiShadows;
}

const baseOptions: ThemeOptions = {
  direction: "ltr",
  typography,
  components: {
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          borderRadius: 3,
          overflow: "hidden"
        }
      }
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: 32
        }
      }
    },
    MuiChip: {
      styleOverrides: {
        root: {
          backgroundColor: "rgba(0,0,0,0.075)"
        }
      }
    },
    MuiCardHeader: {
      styleOverrides: {
        action: {
          marginTop: 0,
          marginRight: 0
        }
      }
    }
  }
};

const themesOptions: CustomThemeOptions[] = [
  {
    name: THEMES.LIGHT,
    palette: {
      mode: "light",
      action: {
        active: "#D2352A"
      },
      background: {
        default: colors.common.white,
        dark: "#f4f6f8",
        paper: colors.common.white
      },
      primary: {
        main: "#D2352A"
      },
      secondary: {
        main: colors.blueGrey[800]
      },
      text: {
        primary: colors.blueGrey[900],
        secondary: colors.blueGrey[600]
      }
    },
    shadows: softShadows
  },
  {
    name: THEMES.DARK,
    palette: {
      mode: "dark",
      action: {
        active: colors.red[500]
      },
      background: {
        default: colors.blueGrey[800],
        dark: colors.grey[900],
        paper: colors.blueGrey[900]
      },
      primary: {
        main: colors.red[500]
      },
      secondary: {
        main: colors.grey[200]
      },
      text: {
        primary: colors.common.white,
        secondary: colors.blueGrey[50]
      }
    },
    shadows: strongShadows
  }
];

export const createTheme = (config: ThemeConfig = {}): Theme => {
  let themeOptions = themesOptions.find((theme) => theme.name === config.theme);

  if (!themeOptions) {
    console.warn(new Error(`The theme ${config.theme} is not valid`));
    [themeOptions] = themesOptions;
  }

  let theme = createMuiTheme(
    adaptV4Theme(_.merge({}, baseOptions, themeOptions))
  );

  return theme as Theme;
};

type VehicleStatusColors = { [key in VehicleStatusType]: Color };

export const vehicleStatusColors: VehicleStatusColors = {
  free: colors.lightGreen,
  booked: colors.indigo,
  driving: colors.red,
  paused: colors.amber,
  unknown: colors.blueGrey
};

type DamageStatusColors = { [key in DamageStatus]: Color };

export const damageStatusColors: DamageStatusColors = {
  none: colors.amber,
  toinvestigate: colors.lightBlue,
  tofix: colors.red,
  keep: colors.indigo,
  ignore: colors.blueGrey,
  fixed: colors.lightGreen
};

type TripEventColors = { [key in CombinedTripEventType]: Color };

export const tripEventColor: TripEventColors = {
  Trip: colors.lightGreen,
  Vehicle: colors.blueGrey,
  Risk: colors.yellow,
  Risk_heavy: colors.orange,
  Risk_super: colors.red,
  Telemetry: colors.blue,
  Fueling: colors.indigo,
  Challenge: colors.purple,
  Trail: colors.teal,
  DamageDetection: colors.cyan,
  UserLog: colors.pink
};

type VehicleCleanStatusColors = { [key in VehicleCleanStatus]: Color };

export const vehicleCleanStatusColors: VehicleCleanStatusColors = {
  dirty: colors.red,
  neutral: colors.orange,
  clean: colors.green
};

type RiskScoreColors = { [key in RiskScore]: Color };

export const riskScoreColors: RiskScoreColors = {
  bad: colors.red,
  neutral: colors.orange,
  good: colors.green
};

type GeofencingTypeColors = { [key in GeofencingType]: string };

export const geofencingTypeColors: GeofencingTypeColors = {
  noparking: "red",
  parking: "green",
  // parking: "#2255AA",
  throttlenogo: "black",
  throttlelowspeed: "orange",
  dropoff: "#2255AA",
  demand: "purple",
  oversupply: "#546e7a",
  regulatedparking: "#1378c0",
  ignorerisk: "blue",
  beacon: "#008080" // Chat GPT has chosen this color
};

type InvoiceStatusColors = {
  [key in Invoice["status"] | "refunded" | "partially refunded"]: Color;
};

export const invoiceStatusColors: InvoiceStatusColors = {
  paid: colors.green,
  refused: colors.red,
  pending: colors.lightBlue,
  cancelled: colors.purple,
  error: colors.red,
  draft: colors.grey,
  deferred: colors.blueGrey,
  retried: colors.blueGrey,
  refunded: colors.purple,
  "partially refunded": colors.blue,
  writtenoff: colors.grey
};

type ScheduleStatusColors = { [key in Schedule["status"]]: Color };

export const scheduleStatusColors: ScheduleStatusColors = {
  upcoming: colors.lightBlue,
  searching: colors.orange,
  booked: colors.indigo,
  resolved: colors.green,
  cancelled: colors.grey
};

export const interviewStatusColors = {
  scheduled: colors.lightBlue,
  done: colors.green,
  cancelled: colors.grey
};

type RepairStatusColors = { [key in Repair["status"]]: Color };
export const repairStatusColors: RepairStatusColors = {
  new: colors.grey,
  waiting: colors.red,
  wayToGarage: colors.blueGrey,
  waitingForQuote: colors.red,
  requestForApproval: colors.orange,
  approved: colors.lightBlue,
  inRepair: colors.purple,
  backToZone: colors.blueGrey,
  done: colors.green,
  archived: colors.grey
};

type FineStatusColors = { [key in FineStatus]: Color };
export const fineStatusColors: FineStatusColors = {
  draft: colors.grey,
  pending: colors.blue,
  contested: colors.purple,
  done: colors.green,
  archived: colors.grey,
  hold: colors.orange
};

export function getTrustScoreLabelColor(trustScore?: number): Color {
  if (trustScore === undefined) {
    return colors.grey;
  }
  if (trustScore >= 50) {
    return colors.green;
  }
  if (trustScore >= 0) {
    return colors.yellow;
  }
  return colors.red;
}

type DunningStateColors = { [key in DunningState]: Color };
export const dunningStateColors: DunningStateColors = {
  active: colors.red,
  active_short_term_monitoring: colors.red,
  active_initiate_court: colors.red,
  active_payment_pause: colors.red,
  active_installment_plan: colors.red,
  paid: colors.green,
  acquired_by_pair: colors.grey,
  stopped: colors.orange,
  stopped_fraud: colors.orange,
  stopped_cancelled: colors.orange,
  new: colors.blueGrey,
  paused: colors.orange,
  paused_dispute: colors.orange,
  paused_client_feedback_expected: colors.orange,
  paused_waiting_for_creditor_feedback: colors.orange,
  merged: colors.grey
};
